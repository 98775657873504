
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.App {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-image: url('./assets/donk2.jpeg'); 
  background-size: cover;
  background-attachment: fixed;
  color: #C5C6C7; 
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
}

main {
  flex: 1; 
  padding: 20px;
}


.largeCardLayout {
  display: flex;
  justify-content: space-between; 
  padding: 2rem 0; 
}

.smallCardLayout {
  display: flex;
  justify-content: center; 
  align-items: flex-end; 
  padding: 0 0 2rem; 
}
.banner {
  background: linear-gradient(145deg, #1a1a2e00, #0d0d1500);
  color: #fff;
  padding: 2rem 0;
  margin: -5rem auto;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  position: relative;
  overflow: hidden;
  width: 80%;
  max-width: 800px;
  background-size: contain; 
  background-position: top center; 
  background-repeat: no-repeat;
}

.banner h1, .banner h2 {
  font-family: 'Press Start 2P', cursive;
  margin: 0;
  animation: neonGlow 2s infinite alternate;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.7);
}

.banner h2 {
  font-size: 1.2rem;
  margin-top: 1rem;
}

@keyframes neonGlow {
  0%, 100% { text-shadow: 0 0 10px rgba(0, 255, 255, 0.7); }
  50% { text-shadow: 0 0 20px rgba(0, 255, 255, 1); }
}



@media (max-width: 768px) {
  .App {
    background-image: url('./assets/card2.webp'); 
  }

  .largeCardLayout,
  .smallCardLayout {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem; 
  }
}

.tokenomics-btn {
  background-color: #4c4c6d; /* Darker background for buttons */
  color: #fff; /* White text for buttons */
  padding: 0.25rem 0.5rem; /* Reduce padding for smaller buttons */
  border-radius: 0.25rem; /* Slightly rounded corners for buttons */
  font-family: 'Press Start 2P', cursive; /* Retro video game font */
  font-size: 0.6rem; /* Reduce font size for smaller text */
  margin: 0.25rem; /* Add some margin around buttons */
  text-shadow: none; /* Remove any text-shadow */
  border: none; /* No border */
  cursor: pointer; /* Cursor for clickable items */
}

.tokenomics-btn:hover {
  background-color: #5c5c7d; /* Slightly lighter color on hover */
}

@media (max-width: 768px) {
  .banner {
    padding: 1rem; 
    margin-top: 8rem; 
    background-size: cover; 
  }
  .tokenomics-btn {
    font-size: 0.5rem; /* Even smaller font size for small screens */
    padding: 0.25rem 0.5rem; /* Smaller padding for small screens */
  }

  .banner h1,
  .banner h2 {
    font-size: 0.8rem; 
  }
}

@media (max-width: 1200px) {
  .banner {
    width: 80%;
    max-width: 750px;
  }

  .banner h1, .banner h2 {
    font-size: 0.8rem; 
  }
}


@media (max-width: 480px) {
  .banner {
   
    padding: 0.5rem;
    background-size: 150% auto; 
    background-position: center 0; 
  }

  .banner h1, .banner h2 {
    font-size: 0.8rem; 
  }
}