/* CustomHeader.module.css - Part 1 of CSS */

/* Import fonts from Google Fonts or define your own custom fonts */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.creativeHeader {
  background-color: #6d239b54; /* Dark background for the header */
  transition: all 0.3s ease;
}

.logo {
  animation: logoSpin 2s infinite linear;
}

@keyframes logoSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button {
  padding: 8px 16px;
  border-radius: 9999px;
  font-weight: bold;
  font-family: 'Press Start 2P', cursive; /* Retro video game font */
  transition: all 0.3s ease;
  cursor: pointer;
  outline: none;
  border: none;
  position: relative;
  overflow: hidden;
  /* 3D effect */
  box-shadow: 0 4px #999;
}

/* The :active selector styles the button when it's clicked */
.button:active {
  box-shadow: 0 2px #666;
  transform: translateY(4px);
}

/* Radium button styles */
.radiumButton {
  background-color: #ff47da; /* Neon Pink */
  color: #fff;
}

/* Hover effect for Radium button */
.radiumButton:hover {
  background-color: #ff80ed;
}
/* CustomHeader.module.css - Part 2 of CSS */

/* ... previous styles ... */


.buyButton {
    background-color: #7df9ff; /* Electric Blue */
    color: #fff;
  }
  

  .buyButton:hover {
    background-color: #a6feff;
  }
  
  /* Shared hover effect for buttons */
  .button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px #666;
  }
  
  /* Pseudo-elements for a fun sparkle effect on hover */
  .button:hover::before,
  .button:hover::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120%;
    height: 120%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 70%);
    opacity: 0;
    transform: translate(-50%, -50%) rotate(45deg);
    border-radius: 50%;
    animation: sparkle 0.4s forwards;
  }

  .banner {
    background: linear-gradient(145deg, #1a1a2e00, #0d0d1500);
    color: #fff;
    padding: 2rem 0;
    margin: 2rem auto;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    position: relative;
    overflow: hidden;
    width: 80%;
    max-width: 800px;
  }
  
  .banner h1, .banner h2 {
    font-family: 'Press Start 2P', cursive;
    margin: 0;
    animation: neonGlow 2s infinite alternate;
    text-shadow: 0 0 10px rgba(0, 255, 255, 0.7);
  }
  
  .banner h2 {
    font-size: 1.2rem;
    margin-top: 1rem;
  }
  
  
  /* Keyframes for the sparkle effect */
  @keyframes sparkle {
    from {
      opacity: 1;
      transform: translate(-50%, -50%) rotate(45deg) scale(0);
    }
    to {
      opacity: 0;
      transform: translate(-50%, -50%) rotate(45deg) scale(1);
    }
  }
  
/* ... existing header styles ... */

@media (max-width: 768px) {
  .creativeHeader {
    /* Adjust the header layout for smaller tablets */
    flex-direction: column;
    padding: 1rem;
  }

  .logo {
    width: 10rem; /* Adjust logo size for smaller screens */
    height: auto;
  }

  .button {
    font-size: 0.75rem; /* Scale down the font size for the buttons */
    padding: 0.5rem 1rem;
    margin: 0.5rem; /* Reduce margin between buttons */
  }
}

@media (max-width: 480px) {
  .creativeHeader {
    /* Further adjustments for mobile phones */
    padding: 0.5rem;
  }
  
  .logo {
    width: 8rem; /* Even smaller logo for mobile screens */
  }

  /* Adjust button sizes and spacing as needed for smaller devices */
}

  