.largeCard {
    width: calc(25% - 4rem);
    margin: -2rem 2rem 2rem;
    height: 450px;
    background: linear-gradient(145deg, #1a1a2e, #150d0e, transparent);
    color: #fff;
    border-radius: 15px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
    border: 2px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
  }
  
  .cardImage {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  
  .cardTitle {
    font-size: 2rem;
    color: #f8f8f2;
    margin: 0.5rem 0;
    text-shadow: 0 0 10px rgba(0, 255, 255, 0.7);
    font-family: 'Press Start 2P', cursive;
    animation: neonGlow 2s infinite alternate;
  }
  
  .cardDescription {
    font-size: 1.2rem;
    color: #f8f8f2;
    padding: 0 1rem;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
    font-family: 'Press Start 2P', cursive;
  }
  
  .largeCard:hover {
    transform: translateY(-10px);
  }
  
  @keyframes neonGlow {
    0%, 100% { text-shadow: 0 0 10px rgba(0, 255, 255, 0.7); }
    50% { text-shadow: 0 0 20px rgba(0, 255, 255, 1); }
  }

  /* ... existing styles ... */

@media (max-width: 768px) { /* For tablets */
  .largeCard {
    width: 50%; /* Each card takes half the width of the container */
    margin: 1rem; /* Slightly smaller margins */
  }
}

@media (max-width: 480px) { /* For mobile phones */
  .largeCard {
    width: 100%; /* Each card takes the full width of the container */
    margin: 1rem 0; /* Top and bottom margins only */
  }
}

  