/* SmallCard.module.css */
.smallCard {
    width: calc(33.3% - 4rem);
    height: 200px;
    margin: -3rem 2rem;
    background: linear-gradient(145deg, #1a1a2e, #150d0e,transparent);
    color: #fff;
    border-radius: 10px;
    padding: 1rem;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: all 0.3s ease;
    font-family: 'Press Start 2P', cursive;
    position: relative;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
  }
  
  .smallCard::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 0, 255, 0.2), transparent);
    transition: all 0.3s ease;
  }
  
  .smallCard:hover::before {
    left: 100%;
  }
  
  .smallCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
  }
  
  .cardTitle {
    font-size: 1.2rem;
    color: #f8f8f2;
    margin-bottom: 0.5rem;
    text-shadow: 0 0 10px rgba(255, 0, 255, 0.7);
  }
  
  .cardDescription {
    font-size: 0.75rem;
    color: #f8f8f2;
    text-shadow: 0 0 5px rgba(255, 0, 255, 0.5);
  }
  
  @keyframes twinkling {
    0% { background-position: 0 0; }
    100% { background-position: -10000px 5000px; }
  }
  
  .smallCard::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent url('https://www.transparenttextures.com/patterns/psychedelic-pattern.png') repeat;
    animation: twinkling 200s linear infinite;
    pointer-events: none;
  }
  
  @keyframes neonGlow {
    0%, 100% { text-shadow: 0 0 10px rgba(255, 0, 255, 0.7); }
    50% { text-shadow: 0 0 20px rgba(255, 0, 255, 1); }
  }
  
  .cardTitle {
    animation: neonGlow 2s infinite alternate;
  }
  

  
  /* ... existing styles ... */

@media (max-width: 768px) { /* For tablets */
  .smallCard {
    width: 50%; /* Each card takes half the width of the container */
    margin: 1rem; /* Slightly smaller margins */
  }
}

@media (max-width: 480px) { /* For mobile phones */
  .smallCard {
    width: 100%; /* Each card takes the full width of the container */
    margin: 1rem 0; /* Top and bottom margins only */
  }
}
